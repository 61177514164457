#mount {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 650px) {
    #mount {
        display: block;
    }
}
