:root {
    --antd-wave-shadow-color: #ff4d06;
    --colorCustomOrange: #ff4d06;
}

.ant-input {
    border-radius: 2px;

    &.ant-input-hover,
    &:hover,
    &.ant-input-focus,
    &:focus {
        border-color: var(--colorCustomOrange);
        box-shadow: none;
    }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--colorCustomOrange);
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--colorCustomOrange);
    box-shadow: none;
}

/*.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f0f2f5;
}*/

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--colorCustomOrange);
}

.ant-checkbox-checked:after {
    border-color: var(--colorCustomOrange);
}

.ant-checkbox-checked {
    &:after {
        border-color: var(--colorCustomOrange);
    }

    .ant-checkbox-inner {
        background-color: var(--colorCustomOrange);
        border-color: var(--colorCustomOrange);
    }
}

.ant-btn-dangerous.ant-btn-primary {
    background: var(--colorCustomOrange);
    border-color: var(--colorCustomOrange);
}

.ant-radio-checked {
    & .ant-radio-inner {
        border-color: var(--colorCustomOrange);
        &:after {
            background-color: var(--colorCustomOrange);
            border-color: var(--colorCustomOrange);
        }
    }
    &:after {
        border: 1px solid var(--colorCustomOrange);
    }
}


.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
    border-color: var(--colorCustomOrange);
}
