.form-controller {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;

    &__header {
        border-bottom: 1px solid #e8e8e8;
        padding: 0 0 30px;
        text-align: center;
    }

    &__header-title-wrapper {
        display: flex;
        align-items: center;
    }

    &__icon-back {
        border: none;
        box-shadow: -1px 5px 22px 0px #4f4f621f;
        flex: 1 0 40px;

        &.ant-btn-lg {
            border-radius: 12px;
        }
    }

    &__header-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0;
        padding: 0 5px;
        width: 100%;
    }

    &__steps {
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        position: relative;
    }

    &__steps-item {
        align-content: center;
        align-items: center;
        color: #888297;
        display: flex;
        justify-content: space-between;

        &_active {
            color: #000;
            font-weight: bold;

            .form-controller {
                &__steps-item-bullet {
                    background-color: #1890ff;
                    border-color: #1890ff;
                    color: #fff;
                }
            }
        }
    }

    &__steps-item-bullet {
        border: 1px solid #bfbdc5;
        border-radius: 50%;
        color: #bfbdc5;
        height: 32px;
        line-height: 30px;
        text-align: center;
        width: 32px;
    }

    &__steps-item-text {
        line-height: 32px;
        margin-left: 8px;
    }

    &__steps-item-divider {
        background-color: #e8e8e8;
        flex: 1 1 8px;
        height: 1px;
        margin: 0 8px;
    }

    &__main {
        flex: 1;
        padding: 30px 0;

        &_limited-width {
            max-width: 320px;
        }

        .ant-form-item-label {
            padding-bottom: 6px;

            & > label {
                color: #888297;
            }
        }
    }

    &__footer {
        align-items: center;
        border-top: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        padding: 30px 0 0;
    }

    &__footer-item {
        .ant-btn:not(.ant-btn-link) {
            font-weight: 500;

            .anticon {
                margin-right: 8px;
                padding-right: 0;
            }
        }

        .ant-btn-link {
            padding: 0 !important;
            white-space: normal;

            &,
            & > span {
                display: inline;
            }
        }
    }

    &__footer-item .ant-form-item-control-input-content {
        display: flex;
        max-width: 350px;
    }

    &__footer-agreement-checkbox {
        margin-right: 8px;
    }

    &__footer-agreement-label {
        cursor: pointer;
    }

    &__verification-notice {
        margin-bottom: 25px;
        margin-right: 0;
    }

    &__verification-form {
        display: flex;
    }

    &__verification-form-code-wrapper {
        margin-right: 16px;
    }

    &__verification-form-actions {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        justify-content: center;
        line-height: 19px;
        text-align: left;
    }

    &__verification-form-action-retry {
        display: none;
    }

    &__verification-code {
        input {
            background: #f8f8f8 !important;
            border: 2px solid #f8f8f8 !important;
            border-radius: 8px !important;
            font-family: inherit !important;
            font-size: 20px;
            font-weight: bold;
            height: 64px !important;
            line-height: 27px;
            margin-right: 8px;
            width: 52px !important;

            &:last-child {
                margin-right: 0;
            }
        }

        &_error {
            input {
                border-color: #ff5050 !important;
            }
        }
    }

    &__birthday-label {
        margin-bottom: .5rem;
        color: #888297;
    }

    &__birthday-items {
        display: flex;
    }

    &__birthday-item {
        margin-right: 16px;
        width: 64px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__errors {
        p:last-child {
            margin-bottom: 0;
        }
    }

    &__input {
        &_error {
            border-color: #ff5050;
        }
    }

    .ant-form {
        display: contents;
    }

    .ant-form-item:last-child {
        margin-bottom: 0;
    }

    .ant-result {
        padding: 0;
    }

}

@media (max-width: 600px) {
    .form-controller {
        height: 100%;

        &__header-title {
            line-height: 30px;
        }
    }
}

@media (max-width: 550px) {
    .form-controller {
        &__steps {
            justify-content: center;
        }

        &__steps-item {

            &:last-child {
                margin-right: 0;
            }
        }

        &__steps-item:not(.form-controller__steps-item_active) &__steps-item-text {
            display: none;
        }

        &__steps-item-divider {
            flex-grow: 0;
        }

        &__verification-form {
            flex-direction: column;
        }

        &__verification-form-code-wrapper {
            margin-right: 0;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        &__verification-form-actions {
            align-items: center;

            & > .ant-typography {
                text-align: center;
            }
        }

        &___verification-code {
            & > * {
                justify-content: center;
            }
        }

        &__footer {
            display: block;
        }

        &__footer-item {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            .ant-btn.ant-btn-round {
                display: block;
                width: 100%;
            }
        }
    }
}

@media (max-width: 360px) {
    .form-controller__main.form-controller__main_no-image .ant-result-icon {
        display: none;
    }
}
