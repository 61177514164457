.privacy-policy {
    &__rules {
        padding-top: 20px;
        text-align: justify;

        & h3 {
            text-align: center;
        }
    }
}
