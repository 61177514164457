:root {
    --baseFontSize: 14px;
}

* {
    box-sizing: border-box;
}

html {
    color: #000;
    font-family: Montserrat, serif !important;
    font-size: var(--baseFontSize) !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 20px !important;
}

body {
    background: #e4e9f0 url('i/bg.svg') 50% 40% no-repeat !important;
    margin: 0;
    min-height: 100vh;
    padding: 0;
}

input,
select {
    font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin: 0;
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */

@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: var(--baseFontSize);
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */

@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    input[type='url'] {
        font-size: var(--baseFontSize);
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */

@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    input[type='url'] {
        font-size: var(--baseFontSize);
    }
}

#mount {
    min-height: 100vh;
}

@media (max-width: 1023px) {
    body {
        background: #fff !important;
    }
}
