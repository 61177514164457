.main-layout {
    display: flex;
    justify-content: center;
    min-height: 100%;

    &__wrapper {
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
        display: block;
        min-height: 670px;
        padding: 40px 80px;
        position: relative;
        width: 710px;
    }

    &__inner {
        height: 100%;
    }

    &__logo {
        background: url('./i/logo.svg') 50% no-repeat;
        background-size: contain;
        height: 75px;
        left: calc(-1 * (90px + 182px));
        position: absolute;
        top: 0;
        width: 182px;

        &_lang_ru {
            background-image: url('./i/logo_ru.svg');
        }
    }
}

@media (max-width: 1250px) {
    .main-layout {
        &__logo {
            width: 138px;
            height: 57px;
            left: calc(-1 * (16px + 138px));
        }
    }
}

@media (max-width: 1023px) {
    .main-layout {
        padding-top: 0;
        min-height: 100vh;

        &__wrapper {
            box-shadow: none;
            border-radius: 0;
            min-height: auto;
        }

        &__logo {
            display: block;
            background: none;
        }
    }
}

@media (max-width: 650px) {
    .main-layout {
        &__wrapper {
            padding: 32px;
        }
    }
}
